<template>
  <div class="container">
    <div class="container-content">
      <div class="top">
        <div class="main-title">新闻<font> / NEWS</font>
          <div class="sub-title">企业动态 及时报道</div>
        </div>
      </div>
      <div class="body">
        <!-- <div class="title">
          <div @click="selectLabel(index)" v-for="(label,index) in labels"
            :class="{label:true, actived:labelIndex == index}" :key="index">{{label}}
          </div>
        </div> -->
        <div class="content">
          <NewsItem v-for="(item,key) in list" :key="key" :item="item" />
        </div>
        <div class="foot">
          <Pager @push="pushItemList" :limit="6" :param="param" url="/official/page/news_page" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItem from "./item.vue";
import Pager from "@/components/common/pager.vue";
export default {
  components: {
    NewsItem,
    Pager,
  },
  data() {
    return {
      param: {
        hot: 0,
        news: 0,
      },
      labels: ["全部", "最新", "最热"],
      labelIndex: 0,
      list: [],
    };
  },
  methods: {
    pushItemList(items) {
      this.list = items;
    },
    newHoverHanlder(idx) {
      this.picActived = false;

      this.itemIndex = idx;
      setTimeout(() => {
        this.picActived = true;
      }, 500);
    },
    selectLabel(index) {
      if (index == this.labelIndex) {
        return;
      }
      this.labelIndex = index;
      switch (index) {
        case 0:
          this.param = {
            hot: 0,
            news: 0,
          };
          break;
        case 1:
          this.param = {
            hot: 0,
            news: 1,
          };
          break;
        case 2:
          this.param = {
            hot: 1,
            news: 0,
          };
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 1330px;
  width: 100%;

  .container-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-title {
        padding-top: 80px;
        font-size: $font-size-36;
        color: $font-color-black;

        font {
          color: $font-color-orange;
        }
      }
      .sub-title {
        text-align: center;
        margin-top: 10px;
        font-size: $font-size-16;
        color: $font-color-gray;
      }
    }

    .body {
      width: 100%;
      margin-top: 50px;
      height: 815px;
      min-width: 1135px;

      .title {
        height: 48px;
        display: flex;

        .label {
          margin-right: 20px;
          width: 123px;
          height: 48px;
          background: #f5f5f5;
          border-radius: 24px;

          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 16px;
          color: #999999;
          line-height: 22px;
          cursor: pointer;

          &:hover {
            background: #ff6f1b;
            color: white;
          }
          &.actived {
            cursor: default;
            background: #ff6f1b;
            color: white;
          }
        }
      }

      .content {
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        justify-content: center;

        > * {
          margin-right: 35px;
          margin-top: 35px;

          cursor: pointer;

          &:hover {
            transition: 0.75s;
            transform: scale(1.05);
          }
        }
        > *:nth-child(3n) {
          margin-right: 0;
        }
      }

      .foot {
        margin-top: 83px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 69px;
      }
    }
  }
}
</style>