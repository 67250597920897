<template>
  <div>
    <ul>
      <li @click="push(pageCurr - 1)" class="pre"></li>
      <li @click="push(page)" :class="{page:true, curr:pageCurr == page}" v-for="page in range(startPage, endPage)"
        :key="page">{{page}}</li>
      <li v-show="pageCurr < pageTotal" class="more"></li>
      <li @click="push(pageCurr + 1)" class="next"></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageTotal: 1,
      pageCurr: 0,
      list: [],
    };
  },
  created() {
    this.push(1);
  },
  watch: {
    param: {
      deep: true,
      handler() {
        this.pageCurr = 0;
        this.push(1);
      },
    },
  },
  props: {
    limit: {
      type: Number,
      default() {
        return 0;
      },
    },
    url: {
      type: String,
      default() {
        return "";
      },
    },
    param: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    startPage() {
      return Math.max(this.pageCurr - 1, 1);
    },
    endPage() {
      return Math.min(this.pageCurr + 1, this.pageTotal);
    },
  },
  methods: {
    range(start, end) {
      let nums = [];
      for (let i = start; i <= end; i++) {
        nums.push(i);
      }
      return nums;
    },
    push(page) {
      if (this.pageTotal > 0) {
        if (page == this.pageCurr) {
          return;
        }
        if (page <= 0 || page > this.pageTotal) {
          return;
        }
      }

      this.pageCurr = page;
      let params = JSON.parse(JSON.stringify(this.param));
      params.page = this.pageCurr;
      params.limit = this.limit;
      this.$api(this.url, params).then(({ data }) => {
        this.pageTotal = Math.ceil(data.count / params.limit);
        this.$emit("push", data.list);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;

  li {
    width: 32px;
    height: 32px;

    transition: 0.5s;
    background-size: 100%;
    cursor: pointer;
    margin: 0 7.5px;
    user-select: none;

    &.page {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      color: #ff6f1b;
      border: 1px solid #ff6f1b;
      border-radius: 100%;
      font-size: 14px;

      &:hover {
        background: #ff6f1b;
        color: white;
      }

      &.curr {
        cursor: default;
        background: #ff6f1b;
        color: white;
      }
    }

    &.pre {
      background-image: url("/static/img/common/pager/pre.png");

      &:hover {
        background-image: url("/static/img/common/pager/pre_hover.png");
      }
    }

    &.next {
      background-image: url("/static/img/common/pager/next.png");

      &:hover {
        background-image: url("/static/img/common/pager/next_hover.png");
      }
    }

    &.more {
      // opacity: 0.7;
      cursor: default;
      background-image: url("/static/img/common/pager/more.png");
    }
  }
}
</style>