<template>
  <div class="item-container">
    <div class="content">
      <div class="stars">
        <img v-for="star in item.star" :key="star" class="star" src="/static/img/school/star.png" />
      </div>
      <img class="top-pic" :src="item.cover" @click="info(item.id)">
      <div class="title" @click="info(item.id)">{{item.title}}</div>
      <div class="info">
        <div class="text">
          {{dateFormat(item.ctime, "y / m / d")}}<br>
        </div>
        <div class="action">
          <div class="button" @click="info(item.id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    info(id) {
      this.$router.push({ path: "/news_info", query: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 355px;
  height: 390px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.09);
  border-radius: 16px;
  overflow: hidden;

  .content {
    position: relative;

    .stars {
      position: absolute;
      left: 15px;
      top: 15px;

      .star {
        width: 22px;
        height: 25px;
        margin-right: 8px;
      }
    }

    .top-pic {
      width: 355px;
      height: 255px;
    }

    .title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: 20px;
      margin-left: 20px;
    }

    .info {
      display: flex;
      justify-content: space-between;
      margin: 30px 20px 29px 20px;
      align-items: center;

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
      }

      .action {
        .button {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 86px;
          height: 28px;
          background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
          border-radius: 14px;

          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          border: 1px solid #ffffff;

          cursor: pointer;
          transition: 0.5s;

          color: #ff6f1b;
          background: none;
          border: 1px solid #ff6f1b;
          &:hover {
            transform: scale(1.06);
          }
        }
      }
    }
  }
}
</style>