<template>
  <div class="container">
    <!-- <div v-if="false"> -->
    <Carousel :args="args.slide" />
    <!-- </div> -->
    <News />
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import News from "@/components/news/news.vue";
export default {
  components: {
    Carousel,
    News,
  },
  data() {
    return {
      args: {},
    };
  },
  created() {
    this.$api("/official/page/news").then(({ data }) => {
      this.args = data;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

